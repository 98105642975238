import React from 'react';

import { Header } from '../../../components/Header';
import { Form } from '../components/Form';
import { Footer } from '../components/Form/components/Footer';

import { __ } from '../../../../../i18n';

import {
  Container,
} from './ViewStyles';

export function ViewUI({
  currentEntityId,
  isLoading,
  isDisabled,
  onBack,
  hasMessageApprove,
  entity,
  onSubmit,
  canAddPicture,
  defaultValues,
  hasMenuConversations
}) {
  return (
    <Container id="ChannelEdit">
      <Header title={__('Edit Channel')} onBack={onBack} />
      <Form
        isEditable
        currentEntityId={currentEntityId}
        isLoading={isLoading}
        isDisabled={isDisabled}
        hasMessageApprove={hasMessageApprove}
        entity={entity}
        onSubmit={onSubmit}
        canAddPicture={canAddPicture}
        defaultValues={defaultValues}
        hasMenuConversations={hasMenuConversations}
        footer={(
          <Footer
            onCancel={onBack}
            cancelText={__('Discard')}
            submitText={__('Save changes')}
          />
        )}
      />
    </Container>
  );
}
