import { createChannelUseCase } from '../../../../Domain/UseCases/Channel/CreateChannel';
import { uploadChannelPictureUseCase } from '../../../../Domain/UseCases/Channel/UploadChannelPicture';
import { hasMessageApproveUseCase } from '../../../../Domain/UseCases/Channel/HasMessageApprove';
import { convertAddressesToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertAddressesToInput';
import { convertGroupsToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertGroupsToInput';
import { convertImageToBlobUseCase } from '../../../../Domain/UseCases/Channel/ConvertImageToBlob';

export function ViewModel() {
  /**
    * @param {import("../../../Data/Repository/Channel/UpdateChannel").TVariables} variables
  */
  async function createChannel(variables) {
    const result = await createChannelUseCase({ ...variables });
    return result;
  }

  /**
    * @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
  */
  async function uploadChannelPicture(file, variables) {
    const result = await uploadChannelPictureUseCase(file, { ...variables });
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/HasMessageApprove").TVariables} variables
  */
  function hasMessageApprove(variables) {
    const result = hasMessageApproveUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
  */
  function convertAddressesToInput(variables) {
    const result = convertAddressesToInputUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
  */
  function convertGroupsToInput(variables) {
    const result = convertGroupsToInputUseCase(variables);
    return result;
  }

  /**
    * @param {string} iconName
  */
  async function convertImageToBlob(iconName) {
    const result = await convertImageToBlobUseCase(iconName);
    return result;
  }

  return {
    createChannel,
    hasMessageApprove,
    convertAddressesToInput,
    convertGroupsToInput,
    uploadChannelPicture,
    convertImageToBlob
  };
}
