import { useMemo } from 'react';
import { createStore } from '../../../../../store';

export function ViewModel() {
  const store = createStore();
  const locale = store?.app?.locale;

  const shouldSeeConversations = useMemo(() => {
    const someOrganizationHasFeature =
      store.currentUser?.organizations?.nodes?.some(
        (organization) =>
          organization?.featureToggles
            ?.web_menu_conversations_notification_bell,
      );

    const currentOrganizationHasFeature =
      store.currentOrganization?.featureToggles
        ?.web_menu_conversations_notification_bell;

    return someOrganizationHasFeature || currentOrganizationHasFeature;
  }, [store.currentOrganization, store.currentUser]);

  return {
    shouldSeeConversations,
    locale,
  };
}
