import mobx from 'mobx';
import cookie from 'react-cookie';
import { browserHistory } from 'react-router';

import * as utils from './utils';

const requireAuth = (store) => {
  const access_token = cookie.load('access_token');

  if (!access_token && store.access_token) {
    browserHistory.push('/auth/logout');
  }
};

const zendesk = {
  close: () => {
    if (document.getElementsByClassName('zEWidget-launcher--active').length) {
      zE(() => {
        zE.hide();
      });
    }
  },
  open: (store) => {
    if (!document.getElementsByClassName('zEWidget-launcher--active').length) {
      zE(() => {
        if (store.currentUser) {
          zE.identify({
            name: store.currentUser.fullname || '',
            email: store.currentUser.email || '',
            phone: store.currentUser.phone || ''
          });
        }

        zE.show();
      });
    }
  }
};

// Required for client side title and description change, you can add more
//-----------
export default store => mobx.autorun(() => {
  const { app, currentOrganization, currentUser, currentEntity } = store;
  const notifications = currentUser && currentUser.notifications && currentUser.notifications.totalCount ?
    '(' + currentUser.notifications.totalCount + ') ' : '';
  document.lang = app.locale;
  document.title = (app.title) ? (notifications + app.title + ' · ' + app.name) : notifications + app.name;
  document.querySelector("meta[name='description']").content = app.description;

  if (currentOrganization && currentOrganization.color) {
    document.getElementById('theme').innerHTML = `.themed { background: #${currentOrganization.color} !important; color: white !important; }`;
  }

  requireAuth(store);

  const hideChat = window && window.innerWidth <= 992

  if ((app.env === 'production' || app.env === 'beta') && !hideChat) {
    if (utils.hasEntity(store, ['ADMIN', 'STAFF']) || (store.currentLocation && store.currentLocation.pathname.includes('/trial'))) {
      zendesk.open(store);
    }

    if (!utils.hasEntity(store, ['ADMIN', 'STAFF']) && !(store.currentLocation && store.currentLocation.pathname.includes('/trial'))) {
      zendesk.close();
    }
  }
});
