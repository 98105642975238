import * as ApolloDataSource from '../../../../../Data/DataSource/ApolloDataSource';

import { getChannelUseCaseMock, updateChannelUseCaseMock } from '../../../../../Domain/UseCases/Channel/__mocks__/channelUseCase.mock';
import { ViewModel as useViewModel } from '../ViewModel';
import * as GetVisibilityType from '../../../../../Domain/UseCases/Channel/GetVisibilityType';
import * as ValidateTime from '../../../../../Domain/UseCases/Channel/ValidateTime';
import * as GetSelectedUsers from '../../../../../Domain/UseCases/Channel/GetSelectedUsers';
import * as utils from '../../../../../../utils';

describe('<ViewModel />', () => {
  const mocks = {
    entityId: 1
  };

  it('should be able to get channel entity', async () => {
    jest.spyOn(ApolloDataSource, 'Query').mockImplementation(async () => getChannelUseCaseMock);

    const { getChannel } = useViewModel();
    const response = await getChannel({ entityId: mocks.entityId });
    expect(response).toStrictEqual({
      data: {
        __typename: 'Entity',
        addresses: {
          __typename: 'EntityAddressesConnection',
          nodes: [{
            __typename: 'Address', address: '12675922218', type: 'PHONE', userId: null
          }, {
            __typename: 'Address', address: '5519900000007', type: 'PHONE', userId: null
          }, {
            __typename: 'Address', address: 'vinicius.silva@classapp.com.br', type: 'EMAIL', userId: 7
          }]
        },
        description: null,
        disabled: false,
        eid: null,
        fullname: 'Secretaria',
        id: 1,
        ntfEndTime: null,
        ntfStartTime: null,
        picture: null,
        policy: null,
        roles: { __typename: 'EntityRolesConnection', nodes: [{ __typename: 'Group', id: 45, name: 'Staffs access and intern' }] },
        scope: null,
        seeAll: true,
        type: 'STAFF',
        users: {
          __typename: 'EntityUsersConnection',
          nodes: [{
            __typename: 'User', confirmEmail: true, confirmPhone: null, email: 'i+staffwithoutarrived2@classapp.com.br', fullname: 'Staff Without Arrived 2', id: 83, phone: null, tags: { __typename: 'UserTagsConnection', nodes: [] }
          }, {
            __typename: 'User', confirmEmail: false, confirmPhone: false, email: 'vinicius.silva@classapp.com.br', fullname: 'Vinicius Silva', id: 7, phone: null, tags: { __typename: 'UserTagsConnection', nodes: [] }
          }]
        }
      },
      errors: undefined
    });
  });

  it('should be able to update channel entity', async () => {
    jest.spyOn(ApolloDataSource, 'Mutation').mockImplementation(async () => updateChannelUseCaseMock);

    const { updateChannel } = useViewModel();
    const variables = {
      id: '1235111940',
      fullname: 'Canal de Teste',
      addresses: [
        {
          address: 'matheus.kindrazki@arcotech.io',
          type: 'EMAIL',
          tags: [],
          invite: false
        },
        {
          address: '5541988538810',
          type: 'PHONE',
          tags: [],
          invite: false
        }
      ],
      defaultFeatures: [
        'MESSAGE'
      ],
      description: 'Descrição do Canal',
      eid: '1123',
      groups: [
        {
          id: 29852,
          name: '3º Ano A',
          type: null
        },
        {
          id: 29851,
          name: '5º Ano A',
          type: null
        },
        {
          id: 29853,
          name: '6º Ano A',
          type: null
        }
      ],
      invisible: false,
      ntfStartTime: '07:30:00',
      ntfEndTime: '08:00:00',
      scope: [],
      seeAll: false,
      type: 'STAFF',
      updateIfExists: false
    };
    const response = await updateChannel(variables);
    expect(response.data).toStrictEqual({
      node: {
        description: null, disabled: false, eid: null, fullname: 'Secretaria', id: 398, ntfEndTime: null, ntfStartTime: null, picture: null, policy: null, scope: null, seeAll: true, type: 'STAFF'
      }
    });
    expect(response.errors).toStrictEqual(undefined);
  });

  it('should be able to remove ids from channel', async () => {
    jest.spyOn(ApolloDataSource, 'Mutation').mockImplementation(async () => ({ data: 'success', error: null }));

    const { removeIdsFromChannel } = useViewModel();
    const entityId = 1;
    const removedIds = [
      1
    ];

    const response = await removeIdsFromChannel(entityId, removedIds);
    expect(response.data).toStrictEqual(['success']);
    expect(response.errors).toStrictEqual([]);
  });

  it('should be able to test convertAddressesToInput function', () => {
    const { convertAddressesToInput } = useViewModel();
    const variables = {
      selectedUsers: [
        {
          address: '5519900000002',
          tags: [],
          type: 'PHONE',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection'
            },
            __typename: 'User'
          },
          original: true
        },
        {
          address: 'i+admin@classapp.com.br',
          tags: [],
          type: 'EMAIL',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection'
            },
            __typename: 'User'
          },
          original: true
        }
      ],
      originalUsers: [
        {
          address: '5519900000002',
          tags: [],
          type: 'PHONE',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection'
            },
            __typename: 'User'
          },
          original: true
        },
        {
          address: 'i+admin@classapp.com.br',
          tags: [],
          type: 'EMAIL',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection'
            },
            __typename: 'User'
          },
          original: true
        }
      ],
      originalAddresses: [
        {
          address: '5519900000002',
          type: 'PHONE',
          userId: 2,
          __typename: 'Address'
        },
        {
          address: 'i+admin@classapp.com.br',
          type: 'EMAIL',
          userId: 2,
          __typename: 'Address'
        }
      ],
      entity_id: null
    };
    const response = convertAddressesToInput(variables);
    expect(response).toStrictEqual({
      addresses: [{
        address: 'i+admin@classapp.com.br', invite: false, tags: [], type: 'EMAIL'
      }, {
        address: '5519900000002', invite: false, tags: [], type: 'PHONE'
      }],
      removedIds: []
    });
  });

  it('should be able to test convertGroupsToInput function', () => {
    const { convertGroupsToInput } = useViewModel();
    const variables = {
      selectedGroups: [
        {
          id: 1,
          name: 'Group A',
        },
        {
          id: 2,
          name: 'Group B',
        },
        {
          id: 3,
          name: 'Group C',
        }
      ]
    };
    const response = convertGroupsToInput(variables.selectedGroups);
    expect(response).toStrictEqual([{ id: 1, name: 'Group A', type: null }, { id: 2, name: 'Group B', type: null }, { id: 3, name: 'Group C', type: null }]);
  });

  describe('getVisibilityType', () => {
    it('should call getVisibilityTypeUseCase with the correct params', () => {
      const { getVisibilityType } = useViewModel();

      jest.spyOn(GetVisibilityType, 'getVisibilityTypeUseCase').mockReturnValue('public');

      getVisibilityType({ seeAll: true });
      expect(GetVisibilityType.getVisibilityTypeUseCase).toHaveBeenCalledWith({ seeAll: true });
    });

    it('should return getVisibilityTypeUseCase response', () => {
      const { getVisibilityType } = useViewModel();

      jest.spyOn(GetVisibilityType, 'getVisibilityTypeUseCase').mockReturnValue('public');

      const response = getVisibilityType({ seeAll: true });
      expect(response).toBe('public');
    });
  });

  describe('getDefaultValues', () => {
    const data = {
      fullname: 'Canal de Teste',
      eid: '1123',
      description: 'Descrição do Canal',
      picture: {},
      confAttendanceEnabled: true,
      disabled: true,
      ntfEndTime: '08:00:00',
      ntfStartTime: '20:30:00',
      users: {
        nodes: [{}]
      },
      seeAll: true,
      roles: {
        nodes: [{}]
      },
      scope: [{}],
      policy: ['WRITER']
    };

    beforeEach(() => {
      jest.spyOn(ValidateTime, 'validateTimeUseCase').mockReturnValue('2021-01-01T00:00:00.000Z');
      jest.spyOn(GetSelectedUsers, 'getSelectedUsersUseCase').mockReturnValue([]);
      jest.spyOn(utils, 'parseScopeToForm').mockReturnValue([]);
    });

    it('should return formated default values with empty object', () => {
      const { getDefaultValues } = useViewModel();

      const response = getDefaultValues({});
      expect(response).toStrictEqual({
        fullname: '',
        externalId: '',
        description: '',
        picture: null,
        type: 'WITHOUT_STATUS',
        status: 'ENABLED',
        hours: {
          open: '2021-01-01T00:00:00.000Z',
          close: '2021-01-01T00:00:00.000Z'
        },
        selectedUsers: [],
        visibility: {
          status: 'custom',
          selectedGroups: []
        },
        permissions: {
          scope: [],
          policy: []
        }
      });
    });

    it('should return formated default values with object', () => {
      const { getDefaultValues } = useViewModel();

      const response = getDefaultValues(data);
      expect(response).toStrictEqual({
        fullname: data.fullname,
        externalId: data.eid,
        description: data.description,
        picture: data.picture,
        type: 'WITH_STATUS',
        status: 'DISABLED',
        hours: {
          open: '2021-01-01T00:00:00.000Z',
          close: '2021-01-01T00:00:00.000Z'
        },
        selectedUsers: [],
        visibility: {
          status: 'public',
          selectedGroups: [{}]
        },
        permissions: {
          scope: [],
          policy: ['WRITER']
        }
      });
    });

    it('should call validateTime with the correct params', () => {
      const { getDefaultValues } = useViewModel();

      getDefaultValues(data);
      expect(ValidateTime.validateTimeUseCase).toHaveBeenCalledWith({ time: data.ntfStartTime });
      expect(ValidateTime.validateTimeUseCase).toHaveBeenCalledWith({ time: data.ntfEndTime });
    });

    it('should call getSelectedUsers with the correct params', () => {
      const { getDefaultValues } = useViewModel();

      getDefaultValues(data);
      expect(GetSelectedUsers.getSelectedUsersUseCase).toHaveBeenCalledWith(data.users.nodes);
    });

    it('should call scopeToForm with the correct params', () => {
      const { getDefaultValues } = useViewModel();

      getDefaultValues(data);
      expect(utils.parseScopeToForm).toHaveBeenCalledWith(data.scope);
    });
  });
});
