import React from 'react';
import EntityNotificationViewUI from './ViewUI';
import { ViewModel as useViewModel } from './ViewModel';

export const EntityNotificationsViewController = ({
  notifications,
  renderRow,
  pagination,
  onLoadMore,
  onClose,
  directNotifications,
  directNotificationsPagination,
  ...rest
}) => {
  const { shouldSeeConversations, locale } = useViewModel();

  return (
    <EntityNotificationViewUI
      notifications={notifications}
      directNotifications={directNotifications}
      showConversations={shouldSeeConversations}
      renderRow={renderRow}
      directNotificationsPagination={directNotificationsPagination}
      pagination={pagination}
      onLoadMore={onLoadMore}
      onClose={onClose}
      locale={locale}
      {...rest}
    />
  );
};
