import { getChannelUseCase } from '../../../../Domain/UseCases/Channel/GetChannel';
import { updateChannelUseCase } from '../../../../Domain/UseCases/Channel/UpdateChannel';
import { uploadChannelPictureUseCase } from '../../../../Domain/UseCases/Channel/UploadChannelPicture';
import { deleteChannelPictureUseCase } from '../../../../Domain/UseCases/Channel/DeleteChannelPicture';
import { getSelectedUsersUseCase } from '../../../../Domain/UseCases/Channel/GetSelectedUsers';
import { hasMessageApproveUseCase } from '../../../../Domain/UseCases/Channel/HasMessageApprove';
import { validateTimeUseCase } from '../../../../Domain/UseCases/Channel/ValidateTime';
import { getVisibilityTypeUseCase } from '../../../../Domain/UseCases/Channel/GetVisibilityType';
import { isDisabledUseCase } from '../../../../Domain/UseCases/Channel/IsDisabled';
import { convertAddressesToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertAddressesToInput';
import { convertGroupsToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertGroupsToInput';
import { removeIdsFromChannelUseCase } from '../../../../Domain/UseCases/Channel/RemoveIdsFromChannel';
import { VisibilityStatusEnum } from './types/VisibilityStatusEnum.types';
import { parseScopeToForm } from '../../../../../utils';

export function ViewModel() {
  /**
    * @param {import("../../../Data/Repository/Channel/GetChannel").TVariables} variables
  */
  async function getChannel(variables) {
    const result = await getChannelUseCase({ ...variables });
    return result;
  }

  /**
    * @param {import("../../../Data/Repository/Channel/UpdateChannel").TVariables} variables
  */
  async function updateChannel(variables) {
    const result = await updateChannelUseCase({ ...variables });
    return result;
  }

  /**
    * @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
  */
  async function uploadChannelPicture(file, variables) {
    const result = await uploadChannelPictureUseCase(file, { ...variables });
    return result;
  }

  /**
    * @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
  */
  async function deleteChannelPicture(variables) {
    const result = await deleteChannelPictureUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/GetSelectedUsers").TUser} variables
  */
  function getSelectedUsers(variables) {
    const result = getSelectedUsersUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/HasMessageApprove").TVariables} variables
  */
  function hasMessageApprove(variables) {
    const result = hasMessageApproveUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/ValidateTime").TVariables} variables
  */
  function validateTime(variables) {
    const result = validateTimeUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/GetVisibilityType").TVariables} variables
  */
  function getVisibilityType(variables) {
    const result = getVisibilityTypeUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
  */
  function isDisabled(variables) {
    const result = isDisabledUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
  */
  function convertAddressesToInput(variables) {
    const result = convertAddressesToInputUseCase(variables);
    return result;
  }

  /**
    * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
  */
  function convertGroupsToInput(variables) {
    const result = convertGroupsToInputUseCase(variables);
    return result;
  }

  /**
    * @param {number} entityId
    * @param {number[]} removedIds
  */
  async function removeIdsFromChannel(entityId, removedIds) {
    const result = await removeIdsFromChannelUseCase(entityId, removedIds);
    return result;
  }

  /**
   * @param {import('./types/ChannelDefaultValues.types').ChannelDefaultValues} data
   */
  function getDefaultValues(data) {
    return {
      fullname: data?.fullname ?? '',
      externalId: data?.eid ?? '',
      description: data?.description ?? '',
      picture: data?.picture ?? null,
      type: data?.confAttendanceEnabled ? 'WITH_STATUS' : 'WITHOUT_STATUS',
      status: data?.disabled ? 'DISABLED' : 'ENABLED',
      hours: {
        open: validateTime({ time: data?.ntfStartTime ?? '' }),
        close: validateTime({ time: data?.ntfEndTime ?? '' })
      },
      selectedUsers: getSelectedUsers(data?.users?.nodes ?? []),
      visibility: {
        status: data.seeAll ? VisibilityStatusEnum.PUBLIC : VisibilityStatusEnum.CUSTOM,
        selectedGroups: data?.roles?.nodes ?? []
      },
      permissions: {
        scope: parseScopeToForm(data?.scope),
        policy: data?.policy ?? []
      }
    };
  }

  return {
    getChannel,
    updateChannel,
    getSelectedUsers,
    hasMessageApprove,
    validateTime,
    getVisibilityType,
    isDisabled,
    convertAddressesToInput,
    convertGroupsToInput,
    uploadChannelPicture,
    deleteChannelPicture,
    removeIdsFromChannel,
    getDefaultValues,
  };
}
