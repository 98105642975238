import React, { useEffect } from 'react';
import { Button } from '@classapp-tech/edna';
import { themes } from '@classapp-tech/edna-styles';
import { CircleQuestionSolid } from '@classapp-tech/edna-icons';
import { Alert } from '../../../components/Alert';
import { Table } from '../components/Table';
import { Footer } from '../components/Footer';
import { ViewModel as view } from './ViewModel';
import {
  Container, TopContainer, UlContainer, MiddleContainer
} from './View.styles';
import Loading from '../../../../../components/ui/Loading';
import { __ } from '../../../../../i18n';
import { createStore } from '../../../../../store';

import { useSnackbar } from '../../../../../components/kit/Snackbar/hooks/useSnackbar';

export function ViewUI(props) {
  const store = createStore();
  const { toast } = useSnackbar();
  const {
    alertIsExpanded,
    setAlertIsExpanded,
    tableLoading,
    setTableLoading,
    simpleChatNumber,
    attendanceStatusNumber,
    updateChannels,
    setSearch,
    loadMoreChannels,
    loadMoreLoading,
    getChannels,
    search,
    enableProductFeature,
    channels,
    setAttendanceStatusNumber,
    loadSearched,
    setSimpleChatNumber,
    savedChannels,
    disableLoadMore,
    totalCount
  } = view();

  useEffect(() => {
    if (search) {
      loadSearched({
        organizationId: store.currentOrganization.id,
      });
    } else {
      getChannels({
        organizationId: store.currentOrganization.id,
      });
    }
  }, [search]);

  useEffect(() => {
    const attendanceEnabledChannels = savedChannels.filter(channel => channel.confAttendanceEnabled);
    setAttendanceStatusNumber(attendanceEnabledChannels.length);
    setSimpleChatNumber(totalCount - attendanceEnabledChannels.length);
  }, [savedChannels, totalCount]);

  const handleOnSave = async () => {
    try {
      if (navigator.onLine) {
        setTableLoading(true);
        updateChannels().then(async () => {
          await enableProductFeature({ organizationId: store.currentOrganization.id });
          toast({ content: __('Channels are being configured. This process may take a few minutes!'), type: 'WARNING' });
          router.push({ pathname: `/organizations/${store.currentOrganization.id}/channels` });
          window.location.reload();
          setTableLoading(false);
        });
      } else {
        toast({ content: __('No connection available'), type: 'ERROR' });
        setTableLoading(false);
      }
    } catch (error) {
      toast({ content: __('Error configuring channels'), type: 'ERROR' });
      setTableLoading(false);
    }
  };

  const { router } = props;
  return (
    <Container>
      <TopContainer>
        <h1>{__('Configure your Channels')}</h1>
        <p>
          {__('Select the type of each channel in your organization')}
          .
        </p>
        <p style={{ color: 'red' }}>
          {`${__('Attention')}: ${__("When we save this configuration, we'll change all the registered channels")}`}
          .
        </p>

        <Alert
          title={__('How do the channel types differ?')}
          isExpanded={alertIsExpanded}
          onExpand={() => setAlertIsExpanded(!alertIsExpanded)}
          icon={<CircleQuestionSolid fill={themes.classapp.classappLight.colors.pen.main} />}
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            padding: '16px 24px',
            backgroundColor: themes.classapp.classappLight.colors.pen.clear,
            marginBottom: '32px',
            gap: '6px',
          }}
        >
          <UlContainer>
            <li>
              <b>
                {__('Simple conversation')}
                :
              </b>
              &nbsp;
              {__('The services created have a simple conversation structure, without status and do not generate any type of data.')}
            </li>
            <li>
              <b>
                {__('With service status')}
                :
              </b>
              &nbsp;
              {__('The services created have a status (pending, in progress and completed) to facilitate the management of conversations, send CSAT research to evaluate the Service and generate data such as average service time per channel.')}
            </li>
          </UlContainer>
        </Alert>

      </TopContainer>

      <MiddleContainer>
        <Table
          isLoading={tableLoading}
          topColumns={[
            {
              id: 'name',
              label: __('Name'),
            },
            {
              id: 'channelType',
              label: __('Channel Type'),
            },
          ]}
          inputPlaceholder={__('Search... (Select Group)')}
          rows={channels}
          onChangeSearchValue={(value) => setSearch(value)}
          debounceTime={800}
        />
        {
          !disableLoadMore && (
            <Button
              size="small"
              variation="basic"
              disabled={tableLoading || channels.length === 0 || disableLoadMore}
              onClick={() => loadMoreChannels({ organizationId: store.currentOrganization.id })}
            >
              {loadMoreLoading ? (
                <Loading
                  width={16}
                  height={16}
                />
              ) : __('Load more')}
            </Button>
          )
        }

      </MiddleContainer>

      <Footer
        isLoading={tableLoading}
        chatNumber={simpleChatNumber}
        attendanceNumber={attendanceStatusNumber}
        onCancel={() => {
          router.push({ pathname: `/organizations/${store.currentOrganization.id}/channels` });
        }}
        onSave={handleOnSave}
      />

    </Container>
  );
}
