import React from 'react';
import { render, waitFor } from '../../../../../../newRender';
import * as store from '../../../../../../store';

import ViewController from '../ViewController';

import * as viewModel from '../ViewModel';
import * as viewUI from '../ViewUI';

/// import { __ } from '../../../../../../i18n';

describe('ViewController', () => {
  const viewModelReturn = {
    isLoading: true,
    setIsLoading: jest.fn(),
    hasOrganizationDashboard: true,
    setHasOrganizationDashboard: jest.fn(),
    hasConversationFeature: jest.fn(() => true),
    getOrganizationUnleashFeature: jest.fn(() => ({
      data: {
        node: {
          unleashStatus: {
            web_menu_conversations: true,
            menu_conversations_organization_dashboard: true,
          },
        },
      },
    })),
  };

  beforeEach(() => {
    jest.clearAllMocks();

    jest
      .spyOn(viewModel, 'ViewModel')
      .mockImplementation(() => viewModelReturn);

    jest.spyOn(viewUI, 'ViewUI').mockImplementation(() => <div>ViewUI</div>);

    jest.spyOn(store, 'createStore').mockReturnValue({
      currentOrganization: {
        id: '123',
      },
      organization: {
        id: '123',
      },
    });
  });

  it('should be defined', () => {
    const { container } = render(<ViewController />);
    expect(container).toBeInTheDocument();
  });

  it('should call view model functions when useEffect is called', () => {
    render(<ViewController />);

    waitFor(() =>
      expect(viewModelReturn.setHasOrganizationDashboard).toHaveBeenCalledTimes(
        1,
      ),
    );
  });
});
