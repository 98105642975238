export const getOrganizationUnleashMock = {
  data: {
    node: {
      unleashStatus: {
        web_menu_conversations: true,
        menu_conversations_organization_direct_list: true,
      },
    },
  },
};
