import { ViewModel } from '../ViewModel';
import { renderHook } from '@testing-library/react-hooks/dom';
import * as store from '../../../../../../store';

describe('given Notifications ViewModel', () => {
  beforeEach(() => {
    jest.clearAllMocks();
    jest.spyOn(store, 'createStore').mockReturnValue({
      currentUser: {
        entities: [
          {
            isChannel: true,
          },
        ],
      },
      app: {
        locale: 'en',
      },
      currentOrganization: {
        featureToggles: {
          web_menu_conversations_notification_bell: true,
        },
      },
    });
  });

  it('should be able to get the shouldSeeConversations as false if organization does not have the feature', () => {
    jest.spyOn(store, 'createStore').mockReturnValue({
      currentUser: undefined,
      currentOrganization: {
        featureToggles: {
          web_menu_conversations_notification_bell: false,
        },
      },
    });

    const { result } = renderHook(() => ViewModel());

    expect(result.current.shouldSeeConversations).toEqual(false);
  });

  it('should be able to get the shouldSeeConversations as true if organization has the feature', () => {
    const { result } = renderHook(() => ViewModel());

    expect(result.current.shouldSeeConversations).toEqual(true);
  });
});
