import { getChannelRepository } from '../../../Data/Repository/Channel/GetChannel';

/**
* @param {import("../../../Data/Repository/Channel/GetChannel").TVariables} variables
*/
export async function getChannelUseCase(variables) {
  const { data, errors } = await getChannelRepository(variables);
  return {
    data: data.node,
    errors
  };
}
